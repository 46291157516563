import { t } from '@lib/i18n';
import Image from '@ui/Image';
import Slider from '@ui/Slider';
import Typography from '@ui/Typography';
import { useMediaQuery } from '@hooks/useMediaQuery';
import { WebinarCard } from '@routes/webinars/ui/WebinarCard';
import { Link } from 'gatsby';
import { SliderCustomButton } from '@ui/SliderCustomButton';

import PlayLogo from '../../../../../static/images/play.svg';
import ArrowRight from '../../../../../static/images/arrow-right.svg';

const Card = ({ item }) => (
  <a
    href={item.link}
    target="_blank"
    className="border-b-solid group flex h-full flex-col justify-between border-b border-b-tertiary300 pb-1.5 pt-5 sm:h-[428px] sm:pt-4 md:h-[422px]"
  >
    <div className="transition duration-300 group-hover:translate-y-[-24px]">
      <Image src={`/images/blog/${item.image}`} alt={item.image_alt} className="w-full" />
      <Typography className="mt-3 text-xl font-semibold lg-down:text-lg">
        {item.title}
      </Typography>
    </div>
    <div className="mt-5 flex items-center justify-between">
      <Typography color="secondary100" className="text-2xs uppercase group-hover:text-primary400">
        {item.duration}
      </Typography>
      <PlayLogo className="fill-secondary100 group-hover:fill-primary400" />
    </div>
  </a>
);

const VideoSectionSlider = ({ children, className, slidesToShow }) => {
  const isTablet = useMediaQuery('(max-width: 1023px)');
  const isMobile = useMediaQuery('(max-width: 687px)');
  
  return (
    <Slider
      settings={{
        slidesToShow: slidesToShow || (isMobile ? 1 : isTablet ? 2 : 3), 
        arrows: !isMobile, 
        nextArrow: <SliderCustomButton addClassName="!pr-[9px] !pl-[11px] md:!pr-[5px] md:!pl-[7px] !top-[140px]" />, 
        prevArrow: <SliderCustomButton addClassName="!pr-[11px] !pl-[9px] md:!pl-[5px] md:!pr-[7px] !top-[140px]" />,
        infinite: true,
        dots: isMobile,
      }}
      gap={4}
      className={className}
    >
      {children}
    </Slider>
  );
};

const VideoSection = () => {
  const isSmallWide = useMediaQuery('(max-width: 1365px)');
  const isMobile = useMediaQuery('(max-width: 687px)');

  const videos = t('blog.video.cards');
  const cards = t('webinars.events');

  return (
    <div className="relative mx-3 rounded-t-2xl border border-b-0 border-solid border-tertiary200 bg-[linear-gradient(180deg,#FFFFFF_0%,rgba(255,255,255,0.817367)_23.29%,rgba(255,255,255,0.563662)_58.48%,rgba(255,255,255,0)_100%)] pt-5 pb-6 lg-down:mx-1">
      <div className="grid-layout mx-auto">
        <div className="flex items-baseline justify-between md-up:mb-1">
          <Typography variant="header2">
            {t('webinars.header')}
          </Typography>
          <Link to="/webinars" className="group flex items-center gap-0.5">
            <Typography variant="body2" weight="medium" className="!text-primary400">Смотреть все</Typography>
            <ArrowRight className="w-[22px] stroke-primary400 transition group-hover:translate-x-[4px]" />
          </Link>
        </div>
        <VideoSectionSlider className="add-padding" slidesToShow={isMobile ? 1 : isSmallWide ? 2 : 3}>
          {cards.map((card, i) => (
            <WebinarCard key={card.title} item={card} />
          ))}
        </VideoSectionSlider>
      </div>
      <div className="grid-layout mx-auto mt-5">
        <div className="flex items-baseline justify-between">
          <Typography variant="header2">
            {t('blog.video.title')}
          </Typography>
          <a href="https://rutube.ru/channel/41001623/" target="_blank" className="group flex items-center gap-0.5">
            <Typography variant="body2" weight="medium" className="!text-primary400">Смотреть все</Typography>
            <ArrowRight className="w-[22px] stroke-primary400 transition group-hover:translate-x-[4px]" />
          </a>
        </div>
        <VideoSectionSlider>
          {videos.map((video, i) => (
            <Card item={video} key={video.link} />
          ))}
        </VideoSectionSlider>
      </div>
    </div>
  );
};

export default VideoSection;
