import clsx from 'clsx';
import Typography from '@ui/Typography';
import Image from '@ui/Image';
import StaticIcon from '@ui/StaticIcon';
import { t } from '@lib/i18n';
import Button from '@ui/Button';

import './WebinarCard.scss';

const colorsVariants = {
  teal: 'bg-teal100/50',
  orange: 'bg-orange100/50',
  cyan: 'bg-cyan100/50',
  pink: 'bg-pink100/50',
  yellow: 'bg-yellow100/50',
};

const cardContainerStyles = 'block group w-[416px] rounded-lg bg-white p-1 pb-1.5 shadow-[6px_6px_40px_0px_#ECF2FF] sm:w-[327px] lg-down:w-[336px] relative';

const bgRight = 'bg-right';

export const WebinarCard = ({
  item: {
    title, name, date, color, position, link, photo, 
  }, 
}) => {
  return (
    <a href={link} target="_blank" className={cardContainerStyles}>
      <div className={clsx(
        'rounded-t-lg rounded-b-2xl p-2 pb-0 pr-0',
        colorsVariants[color],
        'relative',
      )}
      >
        <div className={clsx(
          'flex h-[184px] flex-col justify-between bg-[url("/images/webinars/bg-waves.svg")] bg-no-repeat', 
          bgRight,
        )}
        >
          <Typography className="z-10 max-w-[240px] text-2xl font-medium leading-[25px] sm:text-xl sm:leading-3 lg-down:max-w-[160px]">{title}</Typography>
          {name && (
            <div className={clsx(
              'w-fit rounded-lg bg-white p-1 shadow-[0px_0px_4px_0px_#3878FF14]',
              'z-10 mr-12 mb-2 self-end',
            )}
            >
              <Typography className="text-[11px] font-semibold leading-[15px]">{name}</Typography>
              <Typography className="text-[11px] leading-[15px]">{position}</Typography>
            </div>
          )}
          <Image src={photo} alt="author photo" className="absolute inset-y-0 right-0 h-full" />
        </div>
      </div>
      <div className="mt-1.5 ml-2 flex h-[20px] gap-1">
        <StaticIcon name="play" className="group-hover:hidden" />
        <Typography className="text-2xs group-hover:hidden">{`${t('webinars.additional_text')} ${date}`}</Typography>
      </div>

      <div className={clsx(
        'absolute bottom-0 left-0 z-30 h-[140px] w-full items-end opacity-0 transition-all duration-500 ease-in-out group-hover:opacity-100',
      )}
      >
        <div className="progressive-blur">
          <div className="relative ml-3 mb-3 flex items-center gap-1">
            <StaticIcon name="play_gradient" className="h-5 w-5" />
            <Typography className="text-xs font-medium">Смотреть вебинар</Typography>
          </div>
        </div>
      </div>
    </a>
  );
};

export const WebinarAnnouncementCard = ({ item: { date, title, link } }) => {
  const onButtonClick = () => {
    window.open(link, '_blank');
  };

  return (
    <div className={clsx(cardContainerStyles, 'relative')}>
      <div className="h-full after:absolute after:inset-0 after:bg-[url('/images/webinars/bg-card.svg')] after:bg-no-repeat after:blur-lg after:content-['']">
        <div className="rounded-lg border border-[#E2EBFF] bg-[linear-gradient(180deg,#FFFFFF_0%,rgba(255,255,255,0.817367)_23.29%,rgba(255,255,255,0.563662)_58.48%,rgba(255,255,255,0)_100%)] p-2">
          <div className="flex items-center gap-1.5">
            <div className="w-4 rounded-lg bg-tertiary100 p-0.5 shadow-[2px_4px_12px_0px_#E7EEFB]">
              <StaticIcon name="calendar" />
            </div>
            <Typography className="font-medium">{date}</Typography>
          </div>
          <Typography variant="header4" className="mt-5 font-medium leading-[25px]">{title}</Typography>
          <Button onClick={onButtonClick} className="mt-3">{t('webinars.registration')}</Button>
        </div>
      </div>
    </div>
  );
};
